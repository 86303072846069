/* https://css-tricks.com/snippets/css/ribbon/ */

@use "../node_modules/rfs/scss" as rfs;

.ribbon {
    @include rfs.font-size(1rem);
    /* This ribbon is based on a 16px font side and a 24px vertical rhythm. I've used em's to position each element for scalability. If you want to use a different font size you may have to play with the position of the ribbon elements */

    display: inline-block;
    box-shadow: 0 10px 15px #000a;
    position: relative;
    background: #6f85c4;
    color: #fff;
    text-align: center;
    padding: .5em .5em; /* Adjust to suit */
    margin: .5em 0em; /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
}

.ribbon:after {
    content: "";
    position: absolute;
    display: block;
    bottom: -1vh;
    border: calc(1vw + 5px) solid #6f85c4;
    z-index: -2;
}

.ribbon:after {
    right: calc(-2vw - 5px);
    border-left-width: 1.5em;
    border-right-color: transparent;
    box-shadow: -10px 1px 10px #000a;
}

.ribbon .ribbon .ribbon-content:after {
    content: "";
    position: absolute;
    display: block;
    border-style: solid;
    border-color: #98aadd transparent transparent transparent;
    bottom: -1em;
}

.ribbon .ribbon-content:after {
    right: 0;
    border-width: 1em 1em 0 0;
}

.non-semantic-protector {
    position: relative;
    z-index: 1;
}

.header {
    @include rfs.font-size(calc(2.5vmin));
    margin-bottom: 0.2rem;
}

.subHeader {
    @include rfs.font-size(calc(2.0vmin));
    margin-bottom: 0.2px;
}

h4 {
    @include rfs.font-size(calc(2.8vmin));
}

p, li {
    @include rfs.font-size(calc(1.6vmin));
    margin-block-start: 0.5vh;
    margin-block-end: 0.5vh;
}
