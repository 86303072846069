@import "../node_modules/rfs/scss";

body {
  background-color: #303030;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.book-block {
  justify-content: center;
  align-items: center;

  // cap the height and width so that neither exceeds the 840/570 ratio
  max-height: min(calc(100vw * 0.67), 100vh);
  max-width: min(calc(100vh / 0.67), 100vw);
  // I want the padding to be 1rem on all sides, except the bottom, which should be 5rem
  padding: 1rem 1rem 12rem 1rem;
  
}

.flip-book {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  display: none;
  z-index: 100;
}

.html-book {
  background-size: cover;
}

.page {
  padding: 0px;

  background-color: hsl(35deg, 55%, 98%);
  color: hsl(35deg, 35%, 35%);
  border: solid 0px hsl(35deg, 20%, 70%);
  overflow: hidden;

  .page-image-right {
    height: 100%;
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-position: right center;    
  }

  .page-image-left {
    height: 100%;
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-position: left center;    
  }

  .page-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    .page-header {
      height: 30px;
      @include font-size(2vmin);
      text-transform: uppercase;
      text-align: right;
    }

    .page-text {
      height: 100%;
      flex-grow: 1;
      @include font-size(1.2vmin);
      text-align: justify;
      color: lightcyan;
      margin: 10px;
      padding-top: 10px;
      box-sizing: border-box;
      border-top: solid 0px hsl(35deg, 55%, 90%);

      a {
        color: skyblue;
        text-decoration: none;
      }

      a:hover {
        color: lightgreen;
      }

    }

    .page-footer {
      height: 30px;
      border-top: solid 0px hsl(35deg, 55%, 90%);
      font-size: 80%;
      color: hsl(35deg, 20%, 50%);
    }
  }

  &.--left {
    border-right: 0;
    box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
  }

  &.--right {
    border-left: 0;
    box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);

    .page-footer {
      text-align: right;
    }
  }

  &.hard {
    background-color: hsl(35deg, 50%, 90%);
    border: solid 1px hsl(35deg, 20%, 50%);
  }

  &.page-cover {
    
    border: solid 1px hsl(35deg, 20%, 50%);

    h2 {
      text-align: center;
      padding-top: 10%;
      @include font-size(calc(1.6vw + 10px));
      color: lightcyan;
    }

    &.page-cover-top {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
    }

    &.page-cover-bottom {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
    }
  }
}
